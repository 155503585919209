import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/app/docs/node_modules/@uchiru/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The Details component is an HTML `}<inlineCode parentName="p">{`<details>`}</inlineCode>{` element without native browser styling that optionally uses the `}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/render-props.html"
      }}>{`render props pattern`}</a>{` to pass its state to child components.`}</p>
    <p>{`You are responsible for rendering your own `}<inlineCode parentName="p">{`<summary>`}</inlineCode>{`. To style your summary element like a `}<a parentName="p" {...{
        "href": "./Button"
      }}>{`Button`}</a>{`, you can use the `}<inlineCode parentName="p">{`as`}</inlineCode>{` prop:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Button as="summary">Summary text</Button>
`}</code></pre>
    <h2>{`With static children`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Details>
  <Button as="summary">Click me</Button>
  <p>This should show and hide</p>
</Details>

`}</code></pre>
    <h2>{`With children as a function`}</h2>
    <p>{`The render function gets an object with the `}<inlineCode parentName="p">{`open`}</inlineCode>{` render prop to allow you to conditionally update UI based on the open state of the dropdown:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<Details>
  {({open}) => (
    <>
      <Button as="summary">
        {open ? 'Hide' : 'Show'}
      </Button>
      <p>This should show and hide</p>
    </>
  )}
</Details>
`}</code></pre>
    <h2>{`Manage the open state manually`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`Details`}</inlineCode>{` element is built to also let you manage the open state and toggle functionality if necessary. Just provide values to the `}<inlineCode parentName="p">{`open`}</inlineCode>{` and `}<inlineCode parentName="p">{`onToggle`}</inlineCode>{` props.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` The `}<inlineCode parentName="p">{`overlay`}</inlineCode>{` prop will not function automatically if you chose to provide your own `}<inlineCode parentName="p">{`open`}</inlineCode>{` state. You'll need to implement this yourself. You can use the `}<inlineCode parentName="p">{`onClickOutside`}</inlineCode>{` prop to implement and customize this behavior.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx",
        "metastring": "live",
        "live": true
      }}>{`<State default={false}>
  {([open, setOpen]) => {
    const handleToggle = (e) => setOpen(e.target.open)
    const handleClickOutside = () => setOpen(false)

    return (
      <Details open={open} onToggle={handleToggle} onClickOutside={handleClickOutside} overlay>
        <Button as="summary">Click me</Button>
        <p>This should show and hide</p>
      </Details>
    )
  }}
</State>
`}</code></pre>
    <h2>{`System props`}</h2>
    <p>{`Details components get `}<inlineCode parentName="p">{`COMMON`}</inlineCode>{` system props. Read our `}<a parentName="p" {...{
        "href": "/about/system-props"
      }}>{`System Props`}</a>{` doc page for a full list of available props.`}</p>
    <h2>{`Component props`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`defaultOpen`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the initial open/closed state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`overlay`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets whether or not element will close when user clicks outside of it`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`open`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Boolean`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Use the open prop if you'd like to manage the open state`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onToggle`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Called whenever user clicks on `}<inlineCode parentName="td">{`summary`}</inlineCode>{` element. If you are controlling your own `}<inlineCode parentName="td">{`open`}</inlineCode>{` state this will be the only function called on click, otherwise it's called before the internal `}<inlineCode parentName="td">{`handleToggle`}</inlineCode>{` function.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`onClickOutside`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Function to call whenever user clicks outside of the Details component. This is optional and only necessary if you are controlling your own `}<inlineCode parentName="td">{`open`}</inlineCode>{` state.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      